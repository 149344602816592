<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userCompensatoryOffApplicationData === undefined"
    >
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'compensatory_off_applications' }"
        >
          referral List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userCompensatoryOffApplicationData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <compensatory-off-application-view-info
            :user-data="userCompensatoryOffApplicationData"
            :custom-fields="customFields"
          />
        </b-col>
      </b-row>
    </template>
    <template v-if="($can('Zircly Admin') || isReviewer ) && userCompensatoryOffApplicationData">
      <!-- Second Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <b-card title="Review Comp Off Application">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Reviewer Reason"
                  label-for="reviewer_reason"
                >
                  <b-form-textarea
                    id="reviewer_reason"
                    v-model="userCompensatoryOffApplicationData.reviewer_reason"
                    placeholder="Write Review for this application"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Application Status"
                  label-for="leave-status"
                >
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    v-if="
                      userCompensatoryOffApplicationData.status == '0' ||
                      userCompensatoryOffApplicationData.status == '2'
                    "
                    @click="saveReview('Approved')"
                  >
                    Approve
                  </b-button>
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    v-if="
                      userCompensatoryOffApplicationData.status == '0' ||
                      userCompensatoryOffApplicationData.status == '1'
                    "
                    @click="saveReview('Rejected')"
                  >
                    Reject
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import compensatoryOffApplicationStoreModule from "../compensatoryOffApplicationStoreModule";
import CompensatoryOffApplicationViewInfo from "./CompensatoryOffApplicationViewInfo.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    CompensatoryOffApplicationViewInfo,
    VueGoodTable,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userCompensatoryOffApplicationData = ref(null);
    const compensatory_off_application = ref({
      name: "",
      id: "",
    });
    const USER_APP_STORE_MODULE_NAME = "app-compensatory_off_applications";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        compensatoryOffApplicationStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch(
        "app-compensatory_off_applications/fetchCompensatoryOffApplication",
        router.currentRoute.params.id
      )
      .then((response) => {
        userCompensatoryOffApplicationData.value = response.data.data;
        compensatory_off_application.value.name =
          userCompensatoryOffApplicationData.value.name;
        compensatory_off_application.value.id =
          userCompensatoryOffApplicationData.value.hashid;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          userCompensatoryOffApplicationData.value = undefined;
        }
      });
    return {
      userCompensatoryOffApplicationData,
      compensatory_off_application,
    };
  },
  data() {
    return {
      pageLength: 3,
      compensatoryOffApplicationChangeFlag: true,
      pageLengthEmployee: 3,
      dir: false,
      isAddNewInterviewSidebarActive: false,
      searchTerm: "",
      customFields: [],
      userDataLocal: this.$cookies.get("userData"),
      is_loading: {
        submit: false,
      },
    };
  },
  created() {
    this.getCustomFields();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    isReviewer() {
      if (
        this.userCompensatoryOffApplicationData &&
        this.userCompensatoryOffApplicationData.reviewer.hashid ===
          this.userDataLocal.hashid
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    viewInterview(id) {
      this.$router.push(`/view/interview/${id}`).catch(() => {});
    },
    assignInterview() {
      console.log("assigning interview...");
      this.isAddNewInterviewSidebarActive = true;
    },
    refetchCompensatoryOffApplicationsData() {
      console.log("refetching started..");
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-compensatory_off_applications/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    saveReview(status) {
      const self = this;
      const formData = new FormData();
      formData.append(
        "reviewer_reason",
        self.userCompensatoryOffApplicationData.reviewer_reason
      );
      formData.append("hashid", self.userCompensatoryOffApplicationData.hashid);
      formData.append("status", status);
      self.is_loading.submit = true;
      self.$store
        .dispatch("app-compensatory_off_applications/saveReview", formData)
        .then((res) => {
          if (res.data.success) {
            self.is_loading.submit = false;
            self.$router.push("/compensatory-off-applications");
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Review saved successfully",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Review saved successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            self.is_loading.submit = false;
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Review Update Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Review Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          self.is_loading.submit = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Holiday Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Holiday Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
</style>